<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.currency')"
          icon="mdi-currency-usd"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col lg="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    v-model="search"
                    append-icon="mdi-magnify"
                  />
                </v-col>
              </v-row>

              <v-spacer />

              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-toolbar-items>

            <TableImg
              :head="headers"
              :data="datas"
              :search="search"
              v-on:edititem="handleDialogEdit"
              v-on:deleteitem="handleDeleteDialog"
            />
          </v-sheet>
        </base-material-card>

        <CurrencyCRUD
          :dialog.sync="dialog"
          @handledata="handleCurrency"
          :myObj="obj"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listCurrency, delCurrency, upsertCurrency } from "@/api/system";

const newObj = () => {
  return {
    id: "",
    name: "",
    code: "",
    rate: 0,
    order: 0,
    note: "",
    date: "",
  };
};

const newUpdata = () => {
  return {
    id: "",
    name: "",
    code: "",
    rate: "",
    order: "",
    note: "",
    date: "", // 时间
  };
};

export default {
  name: "currency",
  components: {
    TableImg: () => import("@/components/tableImg"),
    CurrencyCRUD: () => import("@/components/system/currencyCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.currency") },
      ],
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          align: "center",
          sortable: false,
          width: "50",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "name",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.CurrencySymbol"),
          value: "code",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.exchangeRate"),
          value: "rate",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "date",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
      search: "",
      obj: newObj(),
      deleteObj: {},
    };
  },
  methods: {
    getData() {
      listCurrency()
        .then((data) => {
          this.datas = [...data.items];

          this.datas.forEach((p, k) => {
            p.itemNo = k + 1;
          });
        })
        .catch((err) => {
          console.log("List Currency Error", err);
        });
    },
    handleDialog() {
      this.dialog = true;
    },
    handleCurrency(obj) {
      this.dialog = false;

      upsertCurrency(obj)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log("New Currency: ", err);
        });
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    handleDialog() {
      this.dialog = true;
      this.obj = newUpdata();
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      delCurrency(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => {
          console.log("Currency Delete Error", err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>